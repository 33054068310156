import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkSpacing } from "@egds/react-core/spacing";

import {
  MultiItemSearchForm as SearchForm,
  MultiItemSearchFormProps as SearchFormProps,
  MultiItemSearchFormInputs as SearchFormInputs,
  PackageType,
  FlightsTripType,
} from "@shared-ui/retail-search-tools-product";

import { PackagesSearchFormProps } from "./typings";

/**
 * Flex module https://flexmanager.prod.expedia.biz/app/legacy/moduleStore/show/406
 */
export const PackagesSearchForm = withStores(
  "context",
  "page"
)(
  observer((props: PackagesSearchFormProps) => {
    const { templateComponent, page } = props;

    /* istanbul ignore if */
    if (!templateComponent) {
      return null;
    }

    const { metadata, config } = templateComponent;
    const { id } = metadata;
    const { destinationRegionId, sticky } = config;

    const inputs: SearchFormInputs = {
      packageSearchCriteria: {
        primary: !destinationRegionId
          ? {}
          : {
              destination: {
                airportCode: "",
                regionId: destinationRegionId,
              },
              dateRange: {
                start: {
                  day: 1,
                  month: 1,
                  year: 1970,
                },
                end: {
                  day: 1,
                  month: 1,
                  year: 1970,
                },
              },
            },
      },
      includePackageType: true,
      packageType: PackageType.FLIGHT_HOTEL,
      packageShoppingCriteria: {
        primarySearchCriteria: !destinationRegionId
          ? {}
          : {
              property: {
                destination: {
                  regionId: destinationRegionId,
                },
                rooms: [],
                dateRange: {
                  checkInDate: {
                    day: 1,
                    month: 1,
                    year: 1970,
                  },
                  checkOutDate: {
                    day: 1,
                    month: 1,
                    year: 1970,
                  },
                },
              },
              flight: {
                journeyCriterias: [
                  { departureDate: { day: 1, month: 1, year: 1970 }, destination: "", origin: "" },
                  { departureDate: { day: 1, month: 1, year: 1970 }, destination: "", origin: "" },
                ],
                travelers: [],
                tripType: FlightsTripType.ROUND_TRIP,
              },
            },
      },
    };

    /* istanbul ignore next */
    const searchFormProps: SearchFormProps = {
      inputs,
      redirectToSearchResultOnSubmit: true,
      batchKey: "multi-item-search-form",
      sticky,
      additionalAdapterFields: {
        xPageId: page?.pageId ?? "",
        trending: true,
      },
      enableReflection: true,
    };

    return (
      <UitkSpacing margin={{ small: { blockstart: "unset", blockend: "two" }, medium: { block: "two" } }}>
        <div id={id} data-testid="packages-search-form">
          <SearchForm {...searchFormProps} />
        </div>
      </UitkSpacing>
    );
  })
);

PackagesSearchForm.displayName = "PackagesSearchForm";

export default PackagesSearchForm;
